<!--
 * @Descripttion: 
 * @version: 
 * @Author: alex.yang
 * @Date: 2023-12-26 15:56:04
 * @LastEditors: alex.yang
 * @LastEditTime: 2024-08-19 11:08:01
-->
<script lang="ts" setup>
import {RouterView} from 'vue-router'
import {onMounted, ref,onUnmounted} from "vue";
import {Action, ElMessage, ElMessageBox} from "element-plus";
// import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import {useRouter} from "vue-router";
const router =useRouter();

onMounted(() => {
  if (window.electronAPI) CheckForUpdates();
  router.push({
      name: 'home',
  });
});

onUnmounted(() => {
  router.push({
      name: 'home',
  });
})
// 更新进度
const progress = ref(0);

// 检查更新
const CheckForUpdates = () => {
  window.electronAPI.send('CheckForUpdates');

  // 接收可以更新的命令
  window.electronAPI.on('UpdateAvailable', (ev, res) => {
    ElMessageBox.alert('有新的版本可用(' + res.data.version + ')', '温馨提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      showCancelButton: true,
      callback: (action: Action) => {
        if (action === 'confirm') {
          window.electronAPI.send('DownLoadNow');
        }
      }
    });
  });

  // 更新出错
  window.electronAPI.on('UpdateError', (ev, res) => {
    ElMessage({
      message: res.msg,
      type: 'error',
    });
  });

  // 监听下载进度
  window.electronAPI.on('DownloadProgress', (ev, res) => {
    progress.value = res.toFixed(2);
  });
}
</script>

<template>
  <!-- element plus配置中文 -->
  <!-- 放在这里全局设置为中文 -->
  <!-- <el-config-provider :locale="zhCn">
    <RouterView />
  </el-config-provider> -->
  <router-view/>
  <div class="progress" v-if="progress > 0">
    <div class="progress-info">
      <div class="progress-title">更新进度:</div>
      <el-progress type="circle" :percentage="progress"/>
    </div>
  </div>
</template>

<style lang="scss">
@font-face {
  font-family: 'font-word';
  src: url('/assets/font-family/HONORSansCN-Medium.ttf');
}

html, body,
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  padding: 0;
  height: 100%;
  width: 100%;
  margin: 0;
}

.el-empty__description {
  margin-top: -20px !important;
}

.el-table{
    th.el-table__cell,
    td.el-table__cell {
        position: static;
    }
}

.flex-sb {
  display: flex;
  justify-content: space-between;
}

.flex-sbc {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-fec {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flex-fe {
  display: flex;
  justify-content: flex-end;
}

.flex-cc {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-c {
  display: flex;
  justify-content: center;
}

.flex-wc {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.flex-w {
  display: flex;
  flex-wrap: wrap;
}

.flex-coc {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-ce {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.flex-co {
  display: flex;
  flex-direction: column;
}

.text-center {
  text-align: center !important;
}

.margin-auto {
  margin: 0 auto;
}

.margin-left-auto {
  margin-left: auto;
}

.margin-right-auto {
  margin-right: auto;
}

.req {
  color: red;
  margin-left: 5px;
}

.pointer {
  cursor: pointer;
}

.txt-red {
  color: red;
}

.txt-green {
  color: #259d11;
}

.txt-grey {
  color: #7c7c7c;
}

.el-overlay {
  .el-dialog {
    .el-dialog__headerbtn {
      z-index: 9999;
      top: 10px;

      .el-icon {
        width: 1.2em;
        height: 1.2em;

        svg {
          width: 1.2em;
          height: 1.2em;
        }
      }
    }
  }
}

.print-class {
  padding: 0 !important;
  border-radius: 0 !important;

  * {
    box-sizing: border-box;
  }

  .print-herder {
    font-weight: bold;
    color: #000000;
    font-size: 18px;
    padding: var(--el-dialog-padding-primary) !important;
    border-bottom: 1px solid #cccccc;
  }

  .dialog-footer {
    padding-bottom: var(--el-dialog-padding-primary);
    text-align: center;
  }
}

.print-class2 {

  * {
    box-sizing: border-box;
  }

  .dialog-footer {
    text-align: center;
  }
}

.tour {
  .el-tour__header {
    padding: 0 !important;
  }

  :focus-visible {
    outline: none !important;
  }
}

.close-open {
  .el-message-box__title {
    text-align: center;
    font-weight: bold;
  }
}

.dialog-padding {
  padding: var(--el-dialog-padding-primary) !important;
}

.progress {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgb(171 171 171 / 40%);

  .progress-info {
    background-color: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px 25px;
    border-radius: 10px;
    cursor: pointer;
  }

  .progress-title {
    text-align: center;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 15px;
  }
}
</style>
